<template>
  <div v-if="form">
    <form class="form-box">
      <b-container class="container-box">
        <b-row class="no-gutters">
          <b-col>
            <h1 class="font-weight-bold text-uppercase">REDIRECTION</h1>
          </b-col>
        </b-row>
        <b-row
          class="no-gutters bg-white-border mt-4"
          v-bind:class="$isLoading ? 'd-none' : 'd-block'"
        >
          <b-col class="px-4 px-sm-5 py-4">
            <b-row>
              <b-col md="6" v-for="(item, index) in form.data" :key="index">
                <InputText
                  :textFloat="item.name"
                  :placeholder="item.name"
                  type="text"
                  :name="item.name"
                  isRequired
                  v-model="item.text"
                  :isValidate="$v.form.data.$each.$iter[index].text.$error"
                  :v="$v.form.data.$each.$iter[index].text"
                />
                <p
                  class="text-danger"
                  v-if="!$v.form.data.$each.$iter[index].text.url"
                >
                  Please enter a valid url
                </p>
              </b-col>
            </b-row>

            <!-- Button Cancel  Exit -->
            <b-row class="mt-5">
              <b-col md="6"></b-col>
              <b-col md="6" class="text-sm-right">
                <button
                  type="button"
                  class="btn btn-success btn-details-set ml-md-2 text-uppercase"
                  @click="saveForm(0)"
                  :disabled="isDisable"
                >
                  Save
                </button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </form>

    <!-- Modal Result -->
    <ModalAlert ref="modalAlert" :text="modalMessage" />
    <ModalAlertError ref="modalAlertError" :text="modalMessage" />
    <ModalAlertConfirm
      msg="ยืนยันการลบ ?"
      :text="modalMessage"
      btnConfirm="Delete"
      colorBtnConfirm="danger"
      btnCancel="Close"
      ref="isModalAlertConfirm"
    />
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText";
import ModalAlert from "@/components/modal/alert/ModalAlert";
import ModalAlertError from "@/components/modal/alert/ModalAlertError";
import ModalAlertConfirm from "@/components/modal/alert/ModalAlertConfirm";
import { required, url } from "vuelidate/lib/validators";

export default {
  name: "Promotion",
  components: {
    InputText,
    ModalAlert,
    ModalAlertError,
    ModalAlertConfirm,
  },
  data() {
    return {
      modalMessage: "",
      isDisable: false,
      form: null,
    };
  },
  validations: {
    form: {
      data: {
        $each: {
          text: { required, url },
        },
      },
    },
  },
  mounted: async function () {
    this.$isLoading = true;
    await this.getData();
    this.$isLoading = false;
  },
  methods: {
    getData: async function () {
      let data = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/redirection`,
        null,
        this.$headers,
        null
      );
      if (data.result == 1) {
        this.form = data.detail;
      }
    },
    saveForm: async function (flag) {
      this.$v.form.$touch();
      if (this.$v.form.$error) return;

      this.isDisable = true;
      let resData = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/redirection`,
        null,
        this.$headers,
        this.form
      );
      this.modalMessage = resData.message;
      this.isDisable = false;
      if (resData.result == 1) {
        this.$refs.modalAlert.show();

        await this.getData();
      } else {
        this.$refs.modalAlertError.show();
      }
    },
  },
};
</script>

<style scoped>
.input-box {
  color: #16274a;
  border: 1px solid #bcbcbc;
  border-radius: 0px;
  padding: 5px 10px;
}
</style>